import request from '@/utils/request';
// 获取订单
export const createOrder = (data) => request({
    url: '/api/formation/create_order',
    method: 'POST',
    data
});
// 创建订单
export const insertOrder = (data) => request({
    url: '/api/formation/insert_order',
    method: 'POST',
    data
});
// 订单信息
export const orderInfo= (data) => request({
    url: '/api/formation/order_info',
    method: 'POST',
    data
});
// 支付宝支付
export const alipay= (data) => request({
    url: '/api/formation/wxpay',
    method: 'POST',
    data
});
// 支付回调
export const payYes = (data) => request({
    url: '/api/formation/order_checked',
    method: 'POST',
    data
});